.ContentEditable span.placeholder {
    pointer-events: none;
    display: flex;
    gap: 5px;
    
    img {
        display: inline !important;
        margin: unset !important;
        cursor: pointer;
        pointer-events: all;
    }
}
.ContentEditable:focus-visible {
    outline: 1px solid #282c347f;
    border-radius: 3px;
    // outline: none;
}