@use "sass:math";

$zoomLevel: var(--slide-zoom-level, 1);
$titleFontSize: calc(23px * $zoomLevel);
$titlePadding: calc(20px * $zoomLevel) calc(15px * $zoomLevel) calc(15px * $zoomLevel) calc(15px * $zoomLevel);
$contentTitleFontSize: calc(20px * $zoomLevel);
$contentFontSize: calc(20px * $zoomLevel);
$contentMargin: calc(20px * $zoomLevel) 40px;

.Slide {
    padding-bottom: 80px;
    .add-new-section-btn{
        margin: 40px auto;
    }   
}
.two-section-wrapper {
    flex: 1;
    margin-right: 40px;
    margin-left: 40px;
    .two-section {
        display: flex;
        margin: auto;
        justify-content: center;
        max-width: 1440px !important;
        gap: 30px;
        @media (max-width: 600px) {
            flex-direction: column;
            gap: 0px
        }
        
        div {
            flex: 1;
            
        }
        .Slide-Div.content{
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 0px;
            .content-wrapper{
                height: 100%;
            }
        }
    }
}
.Slide.editing {
    @media (max-width: 1900px) {
        padding-top: 40px;
    }
}
.Slide-Div.content {
    margin: $contentMargin;
    white-space: pre-line;
    gap: 16px;
    align-items: center;
    .content-img {
        width: auto;
        height: auto;
        max-width: 1440px;
        max-height: 50vh;
        margin: auto;
        display: block;
        cursor: pointer;
        @media (max-width: 1400px) {
            max-width: 100%;
        }
    }
    .content-wrapper {
        max-width: 1440px !important;
        border-radius: 20px;
        border: 2px solid #fff;
        background: #fff;
        box-shadow: 3px 3px 36px 2px rgba(26, 26, 26, 0.07);
        padding: 14px 42px;
        position: relative;
        display: flex;
        flex: 1;
        @media (max-width: 700px) {
            padding: 14px 24px;
        }
    }
    
}

.Slide-Div {
    transition: transform 0.3s ease;
}
.Slide-Div{
    position: relative;
    display: flex;
    justify-content: center;
    gap: 16px;
    align-items: center;
    // i {
    //     opacity: 0.3;
    // }
    // i:hover {
    //     opacity: 0.7;
    // }
    .add-new-section{
        margin: auto;
        display: table;
        top: 100%;
        right: 100%;
        padding-top: 15px;
        cursor: pointer;
    }
    .edit-new-section-icon {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 16px;
    }
    .delete-confirmation{
        cursor: pointer;
        // position: absolute;
        // right: -25px;
        // top: 50%;
        // transform: translate(0px, -50%);
        font-size: 16px;
    }
    .image-wrapper{
        max-width: 1440px !important;
        padding: 15px;
        // margin: auto;
        position: relative;
        border-radius: 10px;
    }
}

.Slide-Div.title .main-title.index-0 .ContentEditable {
    border: solid 2px #0075ff;
    border-radius: 10px;
    padding: 5px 0px;
}

.Slide-Div.title .main-title {
    color: #0075ff;
    font-size: $titleFontSize;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    padding: $titlePadding;
    position: relative;
    white-space: pre-line;
    text-transform: capitalize;
    flex: 1;
    max-width: 1440px !important;
}
.Slide-Div.title {
    margin: $contentMargin;
    position: relative;
    white-space: pre-line;
    text-transform: capitalize;
}

.Slide-Div {
    transition: transform 0.3s ease;
}

.Slide-Div.content {
    .add-image-in-section {
        cursor: pointer;
    }
    .content-title {
        color: #0567e4;
        font-size: $contentTitleFontSize;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        white-space: pre-line;
        text-transform: capitalize;
        span {
            color: #0075FF;
        }
    }
}
.Slide-Div.isDragging.content .content-wrapper {
    background: rgba(0, 117, 255, 0.2);
}

.Slide-Div.content .content {
    // color: #7a7a7a;
    color: black;
    font-size: $contentFontSize;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: pre-line;
    width: 100%;
    span {
        color: #7A7A7A;
    }
    span.placeholder {
        opacity: 0.5;
    }
    .style-buttons{
        display: inline-flex;
        gap: 24px;
        margin-top: 12px;
        .bottons{
            display: flex;
            gap: 12px;
            img {
                cursor: pointer;
            }
        }
        .colors{
            display: flex;
            gap: 8px;
            .reset-color-icon {
                cursor: pointer;
                margin-top: 2px;
                width: 12px;
                height: 12px;
                max-width: 12px;
                max-height: 12px;
            }
            .circle {
                cursor: pointer;
                margin-top: 2px;
                width: 12px;
                height: 12px;
                max-width: 12px;
                max-height: 12px;
                display: flex;
                background-color: red;
                border-radius: 12px;
            }
        }
    }
    
}
.Slide-Div.content .content-title span {
    color: #0075FF;
}
.Slide-Div.content .content-title span.placeholder {
    opacity: 0.5;
}
