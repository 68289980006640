.welcome-aboard-card {
  width: 561px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 2px solid #fff;
  background: #fff;
  box-shadow: 3px 3px 36px 2px rgba(26, 26, 26, 0.07);
  padding: 69px 20px 55px;
  @media (max-width: 600px) {
    width: 100%;
    padding: 29px 8px 35px;
  }
  .title {
    color: #000;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 12px;
    color: #1a1a1a;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (max-width: 600px) {
      width: 100%;
      padding: 19px 8px 25px;
    }
  }
  .sub-title {
    text-align: center;
    width: 330px;
    color: #7a7a7a;
    text-align: center;
    font-family: Open Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin: auto;
    margin-bottom: 58px;
    @media (max-width: 600px) {
      width: 100%;
      margin-bottom: 38px;
    }
  }
}
