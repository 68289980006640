.navigation {
  display: flex;
  align-items: center;
		// .navigation__list

		&__list {
      display: none;
      column-gap: 32px;
      list-style: none;
      padding: 0;
      margin: 0;
      align-items: center;

      @media (min-width: 768px) {
        display: flex;
      }

      a:not(.btn-isset) {
        color: var(--Black);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 20px */
        letter-spacing: 0.12px;
        text-decoration: none;
      }
		}

		// .navigation__btns

		&__btns {

		}

		// .navigation__mobile

    &__expand {

      display: flex;
      position: relative;
      border: none;
      background-color: transparent;
      flex-direction: column;
      justify-content: space-between;
      width: 38px;
      height: 38px;
      padding: 10px;

      span {
        width: 28px;
        height: 2px;
        background-color: var(--Black);
        transition: all .5s;
      }

      @media (min-width: 768px) {
        display: none;
      }
    }

		&__mobile {
      .navigation__list {
        display: flex;
        position: fixed;
        width: 100%;
        height: calc(100vh - 66px);
        background-color: var(--White);
        top: 66px;
        right: -2000px;
        transition: all .4s;
        flex-direction: column;
        row-gap: 30px;
        padding-top: 76px;
        z-index: 3;

        a:not(.btn-isset) {
          font-weight: 600;
        }
      }

      .navigation__btns {
        padding-top: 80px;
        .btn {
          min-width: 168px;
        }
      }

      &.open {
        .navigation__list {
          right: 0
        }

        .navigation__expand {
          span {
            position: absolute;
    
            &:first-of-type {
              top: 50%;
              transform: rotate(45deg);
            }
    
            &:last-of-type {
              top: 50%;
              transform: rotate(-45deg);
            }
    
            &:nth-of-type(2n) {
              display: none;
            }
          }
        }
      }

      @media (min-width: 768px) {
        display: none;
      }
		}
}