.change-password-wrapper {
  background: white;
  @media (max-width: 600px) {
   display: flex;
  }
}
body .change-password {
  min-height: calc(100vh - 300px);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  @media (max-width: 600px) {
    max-width: 100%;
      width: 100%;
      display: block;
  }
}
.change-password input {
  color: #434343;
  text-align: left;
  text-decoration: none;
  font-size: 13.333px;
  font-style: normal;
  font-weight: 300;
  text-shadow: none;
  line-height: 46px;
  border: 1px solid #dddddd;
  border-radius: 2px;
  padding: 1px 6px 1px 11px;
  width: 100%;
  background-color: #ffffff;
}
.change-password .MuiFormHelperText-root.Mui-error {
  max-width: 358px;
}
@media (max-width: 500px) {
  .change-password input {
    width: 100%;
    font-size: 16px;
    width: 358px;
  }
  .change-password .MuiFormHelperText-root.Mui-error {
    max-width: 100%;
  }
}

.change-password .card-wrapper {
  margin: auto;
  
}
@media (max-width: 778px) {
  .change-password .card-wrapper {
    margin: 15px;
  
  }
}

.change-password .card {
  margin: auto;
  background-color: #ffffff;
  padding: 48px;
  padding-top: 64px;
  display: table;
  border-radius: 32px;
  margin-bottom: 70px;
  display: flex;
  gap: 142px;
  @media (max-width: 600px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  
  @media (max-width: 1250px) {
    gap: 100px;
  }
  @media (max-width: 1150px) {
    gap: 50px;
  }
  @media (max-width: 1100px) {
    flex-direction: column-reverse;
  }
}
.logo {
  margin: 0px 48px;
 }
@media (max-width: 1150px) {
  .logo {
    text-align: center;
  }
}
.change-password .card .left-section {
  max-width: 360px;
}
.change-password .card .right-section {
  display: flex;
  @media (max-width: 700px) {
    display: none;
  }
}

@media (max-width: 900px) {
  .change-password .card {
    flex-direction: column-reverse;
    gap: 30px;
  }

  .change-password .card .left-section {
    text-align: center;
  }
}
.change-password .card.low-pad {
  padding: 40px 50px;
}
@media (max-width: 778px) {
  .change-password .card {
    padding: 45px 0px;
  }
}
@media (max-width: 500px) {
  .change-password .card {
    width: 100%;
  }
}
@media (max-width: 450px) {
  .change-password .card {
    padding: 15px 0px;
  }
}
.change-password .main-title {
  color: #363636;
  text-decoration: none;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  margin-top: 90px;
}
.change-password .mt-30 {
  margin-top: 30px;
}

.change-password .card .title {
  color: var(--Black, #2D2D2D);
  font-family: Open Sans;
  font-size: 36px;
  font-style: normal;
  font-weight: 660;
  line-height: 130%;
  margin-bottom: 28px;
  @media (max-width: 550px) {
    margin-top: 28px;
    font-size: 28px;
  }
}
.change-password .card .title.mb-12 {
  margin-bottom: 12px;
}
.change-password .card .sub-title {
  color: #848484;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin-bottom: 52px;
    @media (max-width: 550px) {
      font-size: 14px;
      margin-bottom: 32px;
    }
}
.change-password .card .title1 {
  color: #363636;
  font-weight: 600;
  font-size: 18.66px;
  text-align: center;
  margin: auto;
  margin-bottom: 35px;
}
.change-password .card .ui-input {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 12px;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding-left: 12px;
  @media (max-width: 550px) {
    width: 100%;
  }
}
/* .change-password input.otp {
  border: none;
  border-bottom: 2px solid #d967be;
} */
.change-password .card .btn-login {
  width: 360px;
  height: 60px;
  margin-top: 12px;
  padding: 11px 24px 11px 20px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 16px;
  border: none;
  background: linear-gradient(0deg, #4285F4 0%, #4285F4 100%), linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
  cursor: pointer;
  color: #FFF;
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  @media (max-width: 550px) {
    width: 100%;
  }
}
.change-password .tnc-note {
  color: #848484;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin-top: 24px; 
}
.change-password .tnc-note a {
  text-decoration: none;
  color: var(--Black, #2D2D2D);
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
}
.change-password .card .btn-login:disabled {
  background-color: gray;
  transform: scale(1);
}
.change-password .links {
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 16px;
}

.change-password .link {
  height: 60px;
  padding: 11px 15px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  color: var(--Primary-Colour, #4285F4);
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  cursor: pointer;
}