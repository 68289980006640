.home-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .card-space {
    .recent-lessons-wrapper {
      display: flex;
      width: 100%;
      max-width: 1010px;
      margin: auto;
      margin-top: 49px;
    }
    .card-wrapper {
      display: flex;
      flex-direction: column;
      margin: auto;
      justify-content: center;
      padding-bottom: 80px;
    }
    flex: 1;
    display: flex;
  }
}
