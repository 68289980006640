.unlimted-access-dialog-loading-wrapper{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 1;
    .unlimted-access-dialog-loading{    
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translate(0,-50%);
        max-height: 200px;
        background-color: white;
    }
}
.unlimted-access-dialog {
    padding: 28px 48px;
    position: relative;
    @media (max-width: 768px) {
        padding: 15px 0px;
    }
    .btn-wrapper {
        margin: auto;
        .btn {
            margin-top: 64px;
            min-width: 410px;
            @media (max-width: 768px) {
                width: 100%;
                min-width: unset;
                margin-top: 30px;
            }
        }
        .trial-notes{
            color: #848484;
            text-align: center;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 170%;
            max-width: 230px;
            margin: auto;
            margin-top: 16px;
        }
    }
    label {
        color: #1A1A1A;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;
        @media (max-width: 768px) {
            font-size: 23px;
            max-width: 100%;
        }
    }
    .sub-title {
        color: #333;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;
        margin-top: 16px;
        @media (max-width: 768px) {
            margin-top: 10px;
            font-size: 16px;
            max-width: 100%;
        }
    }
    .item-title {
        color: #1A1A1A;
        font-family: 'M PLUS Rounded 1c', sans-serif;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 24px;
        margin-bottom: 24px;
        @media (max-width: 768px) {
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 22px;
        }
    }
    ul {
        list-style: none;
        padding: 0px;
    }
    li {
        position: relative;
        color: #333;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;
        padding-left: 28px;
        margin-bottom: 20px;
        @media (max-width: 768px) {
            margin-bottom: 10px;
            font-size: 16px;
        }
    }
    li::before {
        content: " ";
            position: absolute;
            width: 20px;
            height: 20px;
            left: 0;
            top: 4px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M6.23615 4.38299C5.28246 4.48958 4.51908 5.24228 4.40874 6.18571C4.11233 8.72 4.11233 11.2802 4.40874 13.8145C4.51908 14.7579 5.28246 15.5106 6.23615 15.6172C8.71684 15.8945 11.2832 15.8945 13.7639 15.6172C14.7176 15.5106 15.481 14.7579 15.5913 13.8145C15.8043 11.9935 15.8642 10.1592 15.7711 8.33071C15.7687 8.28349 15.7864 8.23743 15.8198 8.204L16.6855 7.33835C16.7856 7.23816 16.9568 7.30081 16.9675 7.44211C17.1309 9.61287 17.086 11.7954 16.8328 13.9597C16.6538 15.4905 15.4247 16.6894 13.9027 16.8595C11.3298 17.147 8.67027 17.147 6.09731 16.8595C4.57532 16.6894 3.34624 15.4905 3.1672 13.9597C2.85951 11.3289 2.85951 8.67127 3.1672 6.0405C3.34624 4.5097 4.57532 3.31083 6.09731 3.14072C8.67027 2.85316 11.3298 2.85316 13.9027 3.14072C14.4388 3.20063 14.9385 3.38816 15.3688 3.67285C15.4535 3.72888 15.4619 3.84791 15.3901 3.9197L14.721 4.58877C14.6663 4.6435 14.5814 4.6527 14.5132 4.61615C14.2853 4.49407 14.0321 4.41297 13.7639 4.38299C11.2832 4.10574 8.71684 4.10574 6.23615 4.38299Z' fill='%232D2D2D'/%3E%3Cpath d='M17.5253 5.02537C17.7694 4.7813 17.7694 4.38557 17.5253 4.14149C17.2812 3.89741 16.8855 3.89741 16.6414 4.14149L9.58334 11.1995L7.52528 9.14149C7.2812 8.89741 6.88547 8.89741 6.6414 9.14149C6.39732 9.38557 6.39732 9.78129 6.6414 10.0254L9.1414 12.5254C9.38547 12.7695 9.7812 12.7695 10.0253 12.5254L17.5253 5.02537Z' fill='%232D2D2D'/%3E%3C/svg%3E");
    }
    img {   
        margin: 16px auto 24px;
        display: flex;
    }
    .blue-btn, .black-btn {
        margin-top: 48px;
        width: 410px;
        @media (max-width: 768px) {
            width: 100%;
            margin-top: 30px;
            .btn{
                margin-top: 30px;
            }
        }
    }
}