.create-lesson {
  height: 100%;
  display: flex;
  flex-direction: column;
  .question-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 39px;
    padding: 15px;
    @media (max-width: 768px) {
      gap: 15px;
      justify-content: flex-start;
      padding: 35px 0px;
    }
    .question {
      display: flex;
      gap: 43px;
      justify-content: center;
      @media (max-width: 768px) {
        gap: 10px;
      }
      .q-number {
        justify-content: center;
        display: flex;
        flex-direction: column;
        span {
          display: flex;
          width: 40px;
          height: 40px;
          padding: 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          border-radius: 100px;
          background: #0075ff;
          color: #f6f6f6;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          @media (max-width: 768px) {
            width: 30px;
            height: 30px;
            font-size: 18px;
          }
        }
      }
      .q-select-input {
        width: 516px;
        height: 75px;
        flex-shrink: 0;
        border-radius: 10px;
        border: 2.5px solid #eaeaea;
        background: #fff;
        color: #333;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        padding-left: 10px;
        fieldset {
          border: none;
        }
        &.error {
          border: 2.5px solid rgba(255, 0, 0, 0.5);
        }
        @media (max-width: 768px) {
          width: auto;
          flex: 1;
          height: 50px;
          padding-left: 5px;
          overflow: hidden;
        }
      }
      .q-input {
        border: none;
        display: flex;
        width: 516px;
        height: 75px;
        flex-direction: column;
        flex-shrink: 0;
        color: rgba(0, 114, 198, 1);
        font-size: 26px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        background: transparent;
        @media (max-width: 768px) {
          width: 100%;
          flex: 1;
          height: 50px;
          font-size: 18px;
        }
      }
      .q-input::placeholder {
        color: rgba(0, 114, 198, 0.45);
      }
      .q-input.error::placeholder {
        color: rgba(255, 0, 0, 0.5);
      }
    }
    .one-more {
      margin-top: 40px;
      @media (max-width: 768px) {
        margin-top: 10px;
      }
    }
  }

  .create-lesson-page-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 36px;
    padding-bottom: 20px;
    @media (max-width: 550px) {
      gap: 26px;
    }
    .specification-wrapper {
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      .specification-header {
        display: flex;
        max-width: 1010px;
        margin: auto;
        width: 100%;
        @media (max-width: 550px) {
          flex-direction: column;
          text-align: left;
        }
        .title {
          flex: 1;
          @media (max-width: 550px) {
            margin-left: 0px;
            margin-bottom: 10px;
          }
        }
        .btn-open-specification {
          margin-bottom: 20px;
          border: none;
          animation: highlightAnimation 2s ease-out;
          @media (max-width: 550px) {
            margin-left: 0px;
          }
        }
      }

      .specification-points,
      .search-specific-field {
        flex: 1;
        // max-wid  th: 490px;
         max-width: 1010px;
      }
      .title {
        margin: auto;
        margin-top: 60px;
        margin-bottom: 25px;
        color: #0072c6;
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        max-width: 1010px;
      }
      .specification-sections {
        display: flex;
        gap: 30px;
        justify-content: center;
        .specification-points {
          textarea {
            width: 100%;
            min-height: 450px;
            flex-shrink: 0;
            border-radius: 20px;
            border: 2.5px solid #eaeaea;
            background: #fff;
            padding: 25px 40px;
            outline-offset: 0px !important;
            outline: none !important;
            resize: none;
            color: rgba(0, 114, 198, 1);
            text-align: justify;
            font-size: 19px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            @media (max-height: 768px) {
              min-height: 350px;
            }
            @media (max-height: 666px) {
              min-height: 250px;
            }
            @media (max-width: 768px) {
              padding: 20px 20px;
              font-size: 17px;
              min-height: 350px;
            }
          }
          textarea::placeholder {
            color: rgba(0, 114, 198, 0.45);
          }
        }
        .search-specific-field {
          border-radius: 20px;
          border: 2px solid #fff;
          background: #fff;
          box-shadow: 60px 3px 36px 2px rgba(26, 26, 26, 0.07);
          padding: 24px 28px;
          .input-wrapper {
            padding-bottom: 12px;
            border-bottom: 1px solid #eaeaea;
            .specific-field-search {
              border: none;
              width: 100%;
            }
          }
        }
      }
    }
    .btn-generate-wrapper {
      display: flex;
      max-width: 1010px;
      margin: auto;
      width: 100%;
      justify-content: center;
    }
  }
}
