.edit-slides-dialog {
    padding: 28px 48px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media (max-width: 768px) {
        padding: 0px 0px;
    }
    .btn-wrapper {
        display: flex;
        width: 380px;
        gap: 48px;
        margin: auto;
        @media (max-width: 600px) {
            width: 100%;
        }
        .btn-delete {
            font-weight: 500;
            font-size: 14px;
            line-height: 44px;
            align-items: center;
            text-align: center;
            color: #31302a;
            flex: none;
            order: 0;
            flex-grow: 0;
            width: 178px;
            background: #f7f5e8;
            border-radius: 8px;
            cursor: pointer;
            background: #31302a;
            color: #ffffff;
        }
    }
    label {
        color: #1A1A1A;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: block;
    }
    .blue-btn, .black-btn {
        margin-top: 48px;
    }
    .lesson-summery-item-wrapper {
        display: flex;
        gap: 10px;
        @media (max-width: 600px) {
            :last-child{
                // display: none;
            }
        }
        .lesson-summery-item {
            flex: 1;
            padding: 16px 25px;
            cursor: pointer;
            @media (max-width: 600px) {
                padding: 12px 15px;
                img {
                    width: 22px;
                }
                .title span{
                    font-size: 14px;
                }
            }
            .ContentEditable{
                cursor: text;
            }
        }
        .delete-confirmation {
            display: flex;
            cursor: pointer;
        }
    }
}