.input-dialog {
    padding: 28px 48px;
    @media (max-width: 600px) {
        padding: 28px 15px;
    }
    .btn-wrapper {
        display: flex;
        width: 380px;
        gap: 48px;
        margin: auto;
        text-transform: capitalize;
        @media (max-width: 600px) {
            width: 100%;
        }
    }
    label {
        color: #1A1A1A;
        text-align: left;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: block;
        text-transform: capitalize;
        @media (max-width: 500px) {
            font-size: 25px;
        }
    }
    .blue-btn, .black-btn {
        margin-top: 48px;
        @media (max-width: 500px) {
            margin-top: 28px;
        }
    }
    input[type="text"] {
        width: 100%;
        height: 75px;
        flex-shrink: 0;
        line-height: 75%;
        color: #8CC0E5;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        border-radius: 10px;
        border: 2.5px solid #EAEAEA;
        padding-left: 30px;
        margin-top: 32px;
        @media (max-width: 500px) {
            margin-top: 20px;
            height: 6cqh;
            padding-left: 15px;
            font-size: 16px;
        }
    }
}