.lesson-body {
    padding: 0px 20px;
    @media (max-width: 600px) {
        padding: 0px;    
    }
}
.lesson-wrapper {
    margin: auto;
    margin-top: 43px;
    display: flex;
    flex-direction: column;
    gap: 60px;
    max-width: 1200px;
    @media (max-width: 600px) {
        flex-direction: column-reverse;
        gap: 40px;
    }
    .lesson {
        display: flex;
        gap: 22px;
        @media (max-width: 600px) {
            flex-direction: column-reverse;
        }
        img {
            width: 100%;
            border-radius: 20px;
            border: 2px solid var(--White, #FFF);
        }
        .left-section {
            flex: 1;
            max-height: CALC(100vh - 300px);
            overflow-y: auto;
            padding-right: 15px;
            .lesson-title {
                color: #1A1A1A;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-top: 22px;
                @media (max-width: 600px) {
                    margin-top: 15px;
                    font-size: 24px;
                }
            }
            .summary {
                padding-top: 15px;
                padding-right: 15px;
                color: #7A7A7A;
                font-size: 19px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                @media (max-width: 600px) {
                    font-size: 16px;
                    padding-right: 0px;
                    text-align: justify;
                }
            }
            img {
                max-height: 40vh;
                width: auto;
                max-width: 100%;
                margin: auto;
                display: flex;
            }
            @media (max-width: 600px) {
                max-height: unset;
                margin-bottom: 40px;
            }
        }
        .right-section {
            flex: 1;
            position: relative;
            .slide-list{
                display: flex;
                flex-direction: column;
                gap: 22px;
                height: CALC(100vh - 300px);
                overflow-y: auto;
                padding-right: 20px;
                padding-left: 20px;
                padding-bottom: 15px;
                @media (max-width: 600px) {
                    height: auto;
                    padding-right: 0px;
                    padding-left: 0px;
                }
            }
            
        }
        .right-section::after {
            /* Create a pseudo-element to cover the bottom part of the div */
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 50px;
            /* Adjust the height as needed */
            background: linear-gradient(to bottom, transparent, #f2f9fe);
            pointer-events: none;
            @media (max-width: 600px) {
                background: unset
            }
        }
    }
    .btn-wrapper{
        display: flex;
        @media (max-width: 600px) {
            flex-direction: column-reverse;
            gap: 5px;
            div {
                margin-left: 0px;
            }
        }
        .space-between{
            flex: 1;
        }
    }

    .btn-get-resources {
        background: rgba(32, 240, 56, 0.2);
        color: black;
        margin-right: 20px;
    }

}