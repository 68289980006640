.lesson-summery-item {
    border-radius: 20px;
    border: 2px solid var(--White, #FFF);
    background: var(--White, #FFF);
    // box-shadow: 3px 3px 36px 2px rgba(26, 26, 26, 0.07);
    filter: drop-shadow(15px 3px 36px rgba(26, 26, 26, 0.07));
    padding: 32px 28px;
    @media (max-width: 600px) {
        filter: unset;
        border: 2px solid #e7e7e766;
        padding: 16px 15px;
    }    

    .title {
        display: flex;
        align-items: center;

        span {
            flex: 1;
            color: #2F2F2F;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            @media (max-width: 600px) {
                font-size: 16px;
            }
            display: flex;
            gap: 6px;
            margin-right: 3px;
            .ContentEditable {
                flex: 1;
            }
        }

        img {
            width: 44px;
            cursor: pointer;
            @media (max-width: 600px) {
                width: 33px;
            }
        }
    }

    ul {
        li {
            margin-bottom: 12px;
            color: #2F2F2F;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}