.blink-wrapper {
    display: inline-flex;
    color: #7a7a7a;
    font-size: 14px;
    line-height: 14px;
    align-items: center;
    .blink-dot {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 5px;
    }
}