.slide-pagination {
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: flex;
    gap: 8px;
    @media (max-width: 600px) {
        bottom: 15px;
        right: 10px;
        gap: 5px;
    }
}

.slide-pagination .slide-page {
    opacity: 0.3;
    border: 1px solid #282c34;
    border-radius: 3px;
    padding: 6px 8px;
    cursor: pointer;
    text-align: center;
    display: flex;
    img {
        filter: invert(1);
        @media (max-width: 600px) {
            margin: 0px -5px;
        }
    }
    @media (max-width: 600px) {
        padding: 4px 5px;
        font-size: 14px;
        line-height: normal;
        display: flex;
        align-items: center;
    }
}
.slide-pagination .slide-page.no-border {
    padding: 6px 0px;
    border: none;
}
.slide-pagination .slide-page.zoom {
    min-width: 27px;
    @media (max-width: 600px) {
        display: none;
    }
}
.slide-pagination .slide-page.divider{
    margin: 0px 15px;
    padding: 0px;
    border-left: 0px;
    @media (max-width: 600px) {
        display: none;
    }
}

.slide-pagination .slide-page.active-slide {
    opacity: 0.4;
    border: 2px solid #282c34;
    font-weight: 600;
}

.slide-pagination:hover .slide-page {
    opacity: 1;
}

.slide-pagination .slide-page.disabled {
    opacity: 0.3;
}

.slide-pagination.back-for {
    left: 30px;
    right: unset;
    @media (max-width: 600px) {
        left: 10px;
        // flex-direction: column-reverse;
    }
}
.slide-pagination-modes {
    display: flex;
    gap: 10px;
    opacity: 0.4;
    position: fixed;
    top: 15px;
    right: 30px;
    cursor: pointer;
}
.slide-pagination-modes.back {
    left: 25px;
    top: 20px;
    opacity: 1;
    display: flex;
    gap: 8px;
    color: #565656;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    img {
        width: 12px;
        height: 20px;
        margin-top: 1px;
    }

    span {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
.slide-pagination-modes.save-changes {
    color: #565656;
    font-size: 16px;
    font-weight: 600;
    opacity: 1;
    cursor: pointer;
}
.slide-pagination-modes.blue-btn{
    opacity: 1;
    @media (max-width: 600px) {
        padding: 8px 15px;
    }
}
.slide-pagination-modes:hover {
    opacity: 1;
}
.press-to-start{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;
    font-weight: 500;
    opacity: 0.4;
    text-align: center;
}