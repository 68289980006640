.delete-dialog {
    padding: 28px 48px;
    @media (max-width: 768px) {
        padding: 15px 0px;
    }
    .loading-wrapper{
        height: 110px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .btn-wrapper {
        display: flex;
        max-width: 472px;
        width: 100%;
        gap: 31px;
        margin: auto;
        @media (max-width: 768px) {
            gap: 15px;
        }
    }
    label {
        margin: auto;
        color: #1A1A1A;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        max-width: 410px;
        display: block;
        width: 100%;
        @media (max-width: 768px) {
            font-size: 23px;
            max-width: 100%;
        }
    }
    .sub-text{
        margin: auto;
        max-width: 410px;
        margin-top: 16px;
        color: #333;
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 170%;
        /* 28.9px */
    }
    .blue-btn, .black-btn {
        width: 100%;
        flex: 1;
        font-size: 17px;
        line-height: 38px;
        max-width: unset;
        margin: 0px;
        margin-top: 48px;
        @media (max-width: 768px) {
            width: 100%;
            margin-top: 30px;
    
            .btn {
                margin-top: 30px;
            }
        }
    }
    .red-btn{
        border: 1px solid #E8372D;
        background: linear-gradient(0deg, #E8372D 0%, #E8372D 100%), linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
    }
}