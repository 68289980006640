.page-layout {
  .p-60 {
    padding-right: 60px;
    padding-left: 60px;
  }
  .page-title {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    font-weight: 700;
    color: #0075ff;
    font-size: 23px;
    font-style: normal;
    line-height: normal;
    position: relative;

    @media (max-width: 600px) {
      flex-direction: row;
      font-size: 19px;
    }
    .back {
      position: absolute;
      cursor: pointer;
      top: 50%;
      left: 0px;
      transform: translate(0px, -50%);
      display: flex;
      gap: 8px;
      color: #565656;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      img {
        width: 12px;
        height: 20px;
        margin-top: 1px;
      }
      span {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    .region {
      position: absolute;
      cursor: pointer;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      display: flex;
      max-width: 930px;
      width: 100%;
      gap: 8px;
      color: #565656;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      .select {
        width: 135px;
        text-align: left;
        margin-left: auto;
        outline: none;
        flex-shrink: 0;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        padding: 0px;
        color: #333;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        background: #fff;
        .MuiSelect-select {
          outline: none;
          padding: 8px 30px 8px 10px;
        }
        fieldset {
          border: none;
        }
        input {
          outline: none;
        }
      }
    }
    .create-lesson-btn {
      position: absolute;
      cursor: pointer;
      top: 50%;
      right: 0px;
      transform: translate(0, -50%);
      @media (max-width: 600px) {
        span {
          display: none;
        }
      }
    }
  }
  .page-title.has-max-width {
    max-width: 1010px;
    margin: 0px auto;
    width: 100%;
  }
  .page-title.has-back {
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
}
