.side-menu-wrapper {
  position: relative;
  height: 100vh;

  .side-menu {
    .drawer-wrapper {
      margin: 9px 19px;
      background: #2d2d2d;
      border-radius: 28px;
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: 1024px;
      @media (max-width: 768px) {
        margin: 9px 12px 9px 5px;
      }
    }

    .drawer-header {
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: center;
      padding: 66px 24px 50px 24px;
      @media (max-width: 768px) {
        padding: 66px 10px 50px 10px;
      }
      .lettered-avatar {
        font-size: 14px !important;
        font-weight: 500;
      }
      .header-profile {
        .post {
          color: #fff;
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0.4px;
          text-transform: uppercase;
          min-width: 122px; 
        }
        
        .name {
          color: #fff;
          text-shadow: 0px 0px 16px 0px #fff;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }
    .active-menu {
      border-radius: 12px;
      background: #413a3a;
      img {
        filter: brightness(100%);
      }
    }
    .menu-list {
      margin-top: "25px";
      margin-bottom: "28px";
      flex: 1;
      .menu-item {
        min-height: 48px;
      }
      .main {
        color: rgba(255, 255, 255, 0.32);
        font-family: Inter;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.4px;
        text-transform: uppercase;
        padding-left: 16px;
        margin-left: 24px;
      }
      .main.close {
        padding-left: 0px;
        margin-left: 0px;
        text-align: center;
      }
    }
  }
  .open-icon {
    top: 78px;
    right: 0px;
    position: absolute;
    transform: translate(50%, 0px);
    cursor: pointer;
    z-index: 2000;
  }
  .close-icon {
    top: 78px;
    right: 0px;
    position: absolute;
    transform: translate(50%, 0px);
    cursor: pointer;
    z-index: 1300;
  }
  .menu-text-lessen-ai {
    color: #0072c6;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-top: 69px;
  }
  .lets-start {
    .lets-start-title {
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 156%;
      letter-spacing: 0.16px;
    }
    .lets-start-sub-title {
      margin-top: 6px;
      color: rgba(255, 255, 255, 0.56);
      max-width: 170px;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%;
      text-wrap: wrap;
      margin: auto;
    }
    .create-lesson-btn {
      margin-top: 20px;
      margin-bottom: 16px;
    }
  }
  .logout-btn-wrapper{
    display: flex;
    gap: 16px;
    padding: 16px 20px;
    margin-bottom: 6px;
    justify-content: center;
    cursor: pointer;
    .text {
      color: rgba(255, 255, 255, 0.56);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
}
