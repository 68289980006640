 * {
   scroll-behavior: smooth;
 }
.landing {
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
  }
  
 
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&family=M+PLUS+Rounded+1c:wght@400;700&family=Open+Sans:wght@300;400;600;700&display=swap');
  
  //Reset styles 
    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
      margin: 0;
      padding: 0;
      border: 0;
      vertical-align: baseline;
    }
  
    /* HTML5 display-role reset for older browsers */
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section {
      display: block;
    }
  
    body {
      line-height: 1;
    }
  
    ol,
    ul {
      list-style: none;
    }
  
    blockquote,
    q {
      quotes: none;
    }
  
    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
      content: '';
      content: none;
    }
  
    table {
      border-collapse: collapse;
      border-spacing: 0;
    }
    h1 {
      color: var(--Black);
      font-size: 36px;
      font-weight: 660;
      line-height: 120%;

      span {
        color: var(--Primary-Colour);
        font-weight: 700;
        font-size: inherit;
      }

      @media (min-width: 768px) {
        font-size: 58px;

      }
    }


    h2 {
      color: var(--Black, #2D2D2D);
      font-size: 28px;
      font-weight: 660;
      line-height: 130%;

      span {
        color: var(--Primary-Colour, #4285F4);
        font-size: inherit;
      }

      @media (min-width: 768px) {
        font-size: 36px;
      }
    }

    h5 {
      color: #414141;
      font-size: 24px;
      font-weight: 600;
      line-height: 130%;
    }

    p {
      color: #848484;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
    }

    a {
      text-decoration: none;
    }

  :root {
    --Black: #2D2D2D;
    --Primary-Colour: #4285F4;
    --White: #fff;
  }

  * {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    box-sizing: border-box;
  }



  .container {
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;

    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: 1200px) {
      padding: unset;
    }

  }

  .logo {
    color: #0075FF;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 23px;
    font-weight: 700;
    line-height: normal;
  }

  .container-fluid {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: 992px) {
      padding: unset;
    }
  }

  .btn {
    display: flex;
    width: 100%;
    padding: 19px 24px 19px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: linear-gradient(0deg, #4285F4 0%, #4285F4 100%), linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);

    color: var(--White);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 130%;
    /* 22.1px */
    text-decoration: none;

    @media (min-width: 768px) {
      width: fit-content;
    }
  }

  .btn-isset {
    color: var(--Primary-Colour);
    padding: 11px 24px 11px 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 125%;
    /* 20px */
    letter-spacing: -0.24px;
    border-radius: 80px;
    border: 1px solid #F0F0F0;
    background: #FBFCFF;
  }

  .btn-medium {
    min-width: 234px;
    min-height: 66px;
  }

  .btn-less {
    padding: 10px 24px;
    font-size: 16px;
  }


.top-section { 
  background: linear-gradient(180deg, #F0F4FB 10.07%, rgba(205, 219, 241, 0.00) 95.02%);
  padding-top: 24px;
  padding-bottom: 100px;
  @media (min-width: 768px) {
    padding-top: 64px;
    padding-bottom: 120px;
  }


		// .top-section__caption
    .container {
      max-width: 1200px;
    }


    &__wrapper {
      border-radius: 40px;
      background: var(--White, #FFF);
      display: flex;
      padding: 20px 28px 28px;
      flex-direction: column-reverse;

      @media (min-width: 768px) {
        column-gap: 63px;
        flex-direction: row;
        padding: 36px 20px 56px 92px;
      }

    }

		&__caption {
      
      @media (min-width: 768px) {
        flex: 0 0 45%;
      }
      h1 {
        margin-bottom: 12px;
        @media (min-width: 768px) {
          margin-bottom: 20px;
        }
      }

      p {
        color: #848484;
        font-size: 20px;
        font-weight: 400;
        line-height: 160%; /* 32px */
        margin-bottom: 35px;

        @media (min-width: 768px) {
          margin-bottom: 63px;
        }
      }

      .btn {
        width: 100%;

        @media (min-width: 768px) {
          min-width: 212px;
          width: fit-content;
          
        }

        @media (min-width: 992px) {
          position: relative;
          &::after {
            content: url(../../assets/images/landing/big-waves.png);
            position: absolute;
            width: 212px;
            height: 36px;
            right: -239px;
          }
        }
      }
		}

		// .top-section__image

		&__image {
      max-width: 545px;
      width: 100%;
      margin-bottom: 12px;

      @media (min-width: 768px) {
        margin-bottom: 0;
      }

      img {
        width: 100%;
      }
		}
}


.how-works {

  h2 {
    text-align: center;
    margin-bottom: 60px;
    @media (min-width: 768px) {
      margin-bottom: 72px;
    }
  }

		// .how-works__list

		&__list {      
      margin-bottom: 180px;


		}
}

.use-flow {
		// .use-flow__item
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    row-gap: 180px;

    @media (min-width: 992px) {
      flex-direction: row;
    }


		&__item {
      min-width: 281px;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 40px;

      &.wave {
        position: relative;
        &::after {
          content: " ";
          background-image: url(../../assets/images/landing/small-waves.png);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          transform: translateX(-50%) rotate(90deg) ;
          bottom: -32%;
          left: 50%;
          width: 101px;
          height: 12px;
          
        }

        @media (min-width: 992px) {
          &::after {
            content: " ";
            background-image: url(../../assets/images/landing/small-waves.png);
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            transform: unset;
            left: unset;
            bottom: 20px;
            right: -20%;
            width: 70px;
            height: 12px;
            
          }
        }

        @media (min-width: 1000px) {
          &::after {
            width: 101px;
            height: 12px;
            right: -30%;
          }
        }



        @media (min-width: 1090px) {
          &::after {
            right: -40%;
          }
        }
      }
		}

		// .use-flow__caption

		&__caption {
      max-width: 244px;
      column-gap: 20px;
      display: flex;
      align-items: center;

      svg {
        display: block;
        width: 26px;
      }

      p {
        color: #414141;
        font-size: 20px;
        font-weight: 600;
        line-height: 130%; /* 26px */

        
      }
		}
}


.integration {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;

  &.container-fluid {
    @media (min-width: 768px) {
      padding-left: 54px;
      padding-right: 30px;
    }

    @media (min-width: 1350px) {
      padding-left: 54px;
      padding-right: 160px;
    }
  }

  @media (min-width: 768px) {
    column-gap: 50px;
    flex-direction: row;
  }

  @media (min-width: 1100px) {
    margin-bottom: 0;
  }
  
  @media (min-width:1350px) {
    column-gap: unset;
    
  }

  
		// .integration__image

		&__image {
      img {
        width: 100%;
      }
		}

		// .integration__text

		&__text {
      max-width: 472px;
		}

		// .integration__badges

		&__badges {
      display: flex;
      column-gap: 16px;

      .badge {
        min-width: 136px;
      }
		}
}

.experience {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;
  margin-bottom: 160px;

  &.container-fluid {
    @media (min-width: 768px) {
      padding-left: 30px;
      padding-right: 40px;
    }

    @media (min-width: 1350px) {
      padding-right: 40px;
      padding-left: 160px;
    }
  }

  @media (min-width: 768px) {
    column-gap: 50px;
    flex-direction: row;
  }

  @media (min-width:1200px) {
    margin-bottom: unset;
  }

  @media (min-width: 1350px) {
    column-gap: unset;
  }


		// .experience__text

		&__text {
      max-width: 456px;
      h2 {
        margin-bottom: 12px;
      }

      p {
        margin-bottom: 45px;
      }
		}

		// .experience__image

		&__image {

      img {
        width: 100%;
      }
		}
}

.pricing {
  padding-left: 30px;
  padding-right: 30px;

  @media (min-width: 992px) {
    padding-left: 30px;
    padding-right: 60px;
  }

  @media (min-width: 1300px) {
    padding: unset;
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    text-align: center;
    margin-bottom: 28px;

    @media (min-width: 992px) {
      margin-bottom: 32px;
      text-align: left;
    }
  }

		// .pricing__plans

		&__plans {
      display: flex;
      margin-bottom: 160px;
      flex-direction: column;
      row-gap: 28px;

      @media (min-width: 992px) {
        flex-direction: row;
        column-gap: 26px;
        row-gap: unset;
      }
      

      .plan {
        flex: 0 0 33.333333%;
      }
		}
}


.faq {
  h2 {
    margin-bottom: 32px;
  }
		// .faq__list

		&__list {
		}
}

.community {
  padding-top: 100px;
  background: linear-gradient(0deg, #F0F4FB 10.07%, rgba(205, 219, 241, 0.00) 95.02%);
  padding-bottom: 200px;

		// .community__wrapper

		&__wrapper {
      border-radius: 32px;
      background: var(--White, #FFF);
      padding: 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column-reverse;

      @media (min-width: 768px) {
        padding: 48px;
        padding-right: 92px;
        flex-direction: row;
      }
		}

		// .community__text

		&__text {
      max-width: 521px;
      h2 {
        margin-bottom: 16px;
      }
      p {
        margin-bottom: 38px;
      }

      .btn {
        min-width: 212px;
        width: 100%;
        @media (min-width: 768px) {
          width: fit-content;
        }

        @media (min-width: 992px) {
          position: relative;
          &::after {
            content: url(../../assets/images/landing/big-waves.png);
            position: absolute;
            width: 212px;
            height: 36px;
            right: -239px;
          }
        }
      }
		}

		// .community__image

		&__image {
      min-width: 341px;
      position: relative;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        margin-bottom: unset;
      }

      img {
        position: relative;
        @media (min-width: 768px) {
          position: absolute;
          top: -200px;
        }
      }
		}
}
}