.landing-style .header {
  background-color: var(--White);
  padding: 16px 0;
  
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.landing-style .header.menu-open{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}
.landing-style .mobile-header-placeholder{
  width: 100%;
  height: 70px;
}