.resources-view-dialog {
  display: flex;
  flex: 1 1;
  justify-content: center;
  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 15px;
    max-width: 1092px;
  }
  .title {
    font-size: 36px;
    font-weight: 700;
    line-height: 49px;
    letter-spacing: 0em;
    text-align: center;
    color: #2baf30;
  }
  .no-resource {
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 50px;
  }
  .create-worksheets-title {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .create-worksheet-input-wrapper {
    width: 100%;
    max-width: 731px;
    border-bottom: 1px solid #20f035;
    margin: 50px auto;
    display: flex;
    .create-worksheet-input {
      border: none;
      width: 100%;
      margin: 10px auto;
      max-width: 565px;
      color: black;
      font-size: 16px;
    }
    .create-worksheet-input::placeholder {
      color: #a4a4a4;
      font-size: 14px;
    }
  }

  .content {
    flex: 1;
    display: block;
    padding: 15px;
    overflow-y: auto;
    .resource-item {
      margin: 1%;
      display: inline-flex;
      flex-direction: column;
      max-width: 23%;
      gap: 2px;
      cursor: pointer;
      .name {
        .status {
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
          color: #2baf30;
        }
        color: black;
        white-space: nowrap;
        /* Prevents the text from wrapping */
        overflow: hidden;
        /* Hide the overflow text */
        text-overflow: ellipsis;
        font-weight: 500;
      }
      .name {
        color: #ff0000;
      }
      .img-container {
        border: 2px solid #ff0000;
        border-radius: 12px;
        width: 100%;
        overflow: hidden;
        position: relative;
        img {
          position: absolute; /* Position the content absolutely */
          top: 0; /* Position it at the top */
          left: 0; /* Position it at the left */
          width: 100%; /* Make it take up the entire width */
          height: 100%;
          left: 0;
          right: 0;
        }
      }
      .img-container:before {
        content: ""; /* Create a pseudo-element */
        display: block; /* Make it a block-level element */
        padding-top: 57.5%; /* Set the padding top to 50% of the container's width */
      }
    }
    .default-item {
      .name {
        color: #2baf30;
      }
      img {
        border: 2px solid #2baf30;
        border-radius: 12px;
      }
    }
    .blue-item {
      .name {
        color: #0075ff;
      }
      img {
        border: 2px solid #0075ff;
        border-radius: 12px;
      }
    }
    .resource-item:hover {
      scale: 1.03;
      transition: 0.2s;
    }
  }
  .btn-create-resources {
    max-width: 300px;
    background: rgba(32, 240, 53, 0.3);
    color: black;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .back {
    cursor: pointer;
    display: flex;
    gap: 8px;
    color: #565656;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    img {
      width: 12px;
      height: 20px;
      margin-top: 1px;
    }

    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .resource-loading {
    height: unset;
    margin-top: 150px;
  }
  .resource-loading.no-m-t {
    margin-top: 0px;
  }
}
