.landing .footer {

  // .footer__top

  &__top {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 28px;
    padding-bottom: 40px;
    border-bottom: 2px solid #EBEBEB;
    align-items: center;

    @media (min-width: 768px) {
      flex-direction: row;
      padding-top: 35px;
      padding-bottom: 56px;
    }
  }

  // .footer__navigation

  &__navigation {
    display: flex;
    column-gap: 32px;
    margin-top: 36px;
    @media (min-width: 768px) {
      margin-top: unset;
    }
    li {
      a {
        color: var(--Black, #2D2D2D);
        font-size: 16px;
        font-weight: 400;
        line-height: 125%; /* 20px */
        letter-spacing: 0.12px;
      }
    }
  }

  // .footer__basement

  &__basement {
    padding-top: 32px;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  // .footer__copyrights

  &__copyrights {
    color: #808080;
    font-size: 14px;
    font-weight: 400;
    line-height: 125%; /* 17.5px */
    letter-spacing: 0.12px;
    text-align: center;
    margin-bottom: 36px;

    @media (min-width: 768px) {
      text-align: left;
    }
  }

  // .footer__links

  &__links {
    display: flex;
    column-gap: 40px;
    justify-content: center;
    margin-bottom: 32px;

    @media (min-width: 768px) {
      column-gap: 32px;
      justify-content: unset;
      margin-bottom: unset;
    }

    li {
      a {
        color: var(--Black, #2D2D2D);
        font-size: 16px;
        font-weight: 400;
        line-height: 125%; /* 20px */
        letter-spacing: 0.12px;
      }
    }
  }
}