.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.blue-btn {
  cursor: pointer;
  padding: 11px 20px;
  display: flex;
  gap: 6px;
  border-radius: 12px;
  background: linear-gradient(0deg, #4285f4 0%, #4285f4 100%),
    linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 0px 4px 24px 0px rgba(168, 82, 5, 0.3);
  max-width: 176px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: var(--white-01, #fff);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: auto;
  border: none;
}

.black-btn {
  cursor: pointer;
  padding: 11px 20px;
  display: flex;
  gap: 6px;
  border-radius: 12px;
  background: linear-gradient(0deg, #2d2d2d 0%, #2d2d2d 100%),
    linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 0px 4px 24px 0px rgba(168, 82, 5, 0.3);
  max-width: 176px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: auto;
  border: none;
}

.btn-v-big {
  font-size: 17px;
  line-height: 38px;
  min-width: 180px;
  box-sizing: unset;
}

.mt-20 {
  margin-top: 20px;
}
.text-center {
  text-align: center;
}

input {
  outline: none;
}

.back-blur {
  border-radius: 20px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f3f0f0 0%,
    rgba(191, 228, 255, 0) 100%
  );
  backdrop-filter: blur(2px);
}

input {
  color: rgba(0, 114, 198, 1);
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: transparent;
}

input::placeholder {
  color: rgba(0, 114, 198, 0.45);
}

input.error::placeholder {
  color: rgba(255, 0, 0, 0.5);
}
.back-shad-wrapper {
  position: absolute;
  top: 44px;
  bottom: 24px;
  left: 165px;
  right: 24px;
  z-index: -1;
}
.back-shad {
  width: 686px;
  height: 882px;
  flex-shrink: 0;
  border-radius: 20px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.d-none {
  display: none;
}

/* ========================================== */






.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.dot {
  width: 20px;
  height: 20px;
  margin: 0 5px;
  border-radius: 50%;
  background: #006DFC;
  animation: dotFlashing 1.4s infinite both;
}

.dot:nth-child(2) {
  animation-delay: .2s;
}

.dot:nth-child(3) {
  animation-delay: .4s;
}

@keyframes dotFlashing {
  0% {
    background-color: #006DFC;;
    transform: scale(1);
  }

  50% {
    background-color: #EFEFEF;
    transform: scale(1.2);
  }

  100% {
    background-color: #006DFC;
    transform: scale(1);
  }
}


.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-text {
  color: #006DFC;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
@media (max-width: 768px) {
  .loading-text {
    padding: 0px 15px;
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 30px;
  margin: 0 20px;
  animation: iconJumping 1.4s infinite both;
  color: #0075FF;
}

.icon:nth-child(2) {
  animation-delay: .2s;
}

.icon:nth-child(3) {
  animation-delay: .4s;
}

@keyframes iconJumping {

  0%,
  100% {
    transform: translateY(0) scale(1);
    /* color: #0075FF; */
  }

  50% {
    transform: translateY(-30px) scale(1.2);
    /* color: #EFEFEF; */
  }
}



/* For Webkit Browsers (Chrome, Safari, newer versions of Opera) */
::-webkit-scrollbar {
  width: 8px;
  /* Width of the vertical scrollbar */
  height: 8px;
  /* Height of the horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: #2d2d2d1b;
  /* Secondary color */
  border-radius: 8px;
  /* Rounded corners */
}

::-webkit-scrollbar-thumb {
  background: #4285f4;
  /* Primary color */
  border-radius: 8px;
  /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #1d74ff;
  /* Slightly darker version of primary color when hovered */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #2d2d2d26 #2d2d2d1b;
  /* thumb and track color */
}

/* You can't set border-radius in Firefox, and IE doesn't support custom scrollbars to this extent */

.max-191 {
  max-width: 191px;
}
.drag-action {
  cursor: grab;
}
.ui-hide{
  opacity: 0 !important;
  height: 0 !important;
  min-height: 0 !important;
  width: 0 !important;
}
.logo {
  text-decoration: none;
  color: #0075FF;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-size: 23px;
  font-weight: 700;
  line-height: normal;
}

@keyframes highlightAnimation {
  0% {
    box-shadow: 0 0 0 0 rgba(45, 45, 45, 0.7);
  }

  100% {
    box-shadow: 0 0 30px 30px rgba(45, 45, 45, 0);
  }
}

@media (max-width: 768px) {
  .dialogCloseIcon {
    display: none;
  }
}
.flex-1{
   flex: 1;
}