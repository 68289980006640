.image-upload-dialog {
    padding: 28px 48px;
    @media (max-width: 768px) {
        padding: 15px 0px;
    }
    .btn-wrapper {
        display: flex;
        width: 380px;
        gap: 48px;
        margin: auto;
        .disabled {
            background: #afafaf;
        }
        @media (max-width: 768px) {
            width: 100%;
            margin-top: 20px;
            .btn {
                margin-top: 20px;
            }
        }
    }
    label {
        color: #1A1A1A;
        text-align: left;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: block;
        @media (max-width: 768px) {
            font-size: 23px;
            max-width: 100%;
        }
    }
    .blue-btn, .black-btn {
        margin-top: 48px;
    }
    input[type="text"] {
        width: 100%;
        height: 75px;
        flex-shrink: 0;
        line-height: 75%;
        color: #8CC0E5;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        border-radius: 10px;
        border: 2.5px solid #EAEAEA;
        padding-left: 30px;
        margin-top: 32px;
    }
    .image-dropzone {
        label {
            text-align: center;
            border-radius: 10px;
            border: 2.5px dashed #8CC0E5;
            color: #333;
            font-family: Open Sans;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 160px;
            margin-top: 32px;
            cursor: pointer;
            @media (max-width: 768px) {
                font-size: 14px;
                max-width: 100%;
            }
        }
    }
    .loading-wrapper{
        margin-top: 32px;
    }
    
    img {
        margin-top: 32px;
        width: 100%;
        max-width: 100%;
        // max-height: 100%;
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
    }
}