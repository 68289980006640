:root {
    --Black: #2D2D2D;
    --Primary-Colour: #4285F4;
    --White: #fff;
}

.landing-style{
//Reset styles 
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

h1 {
    color: var(--Black);
    font-size: 36px;
    font-weight: 660;
    line-height: 120%;

    span {
        color: var(--Primary-Colour);
        font-weight: 700;
        font-size: inherit;
    }

    @media (min-width: 768px) {
        font-size: 58px;

    }
}


h2 {
    color: var(--Black, #2D2D2D);
    font-size: 28px;
    font-weight: 660;
    line-height: 130%;

    span {
        color: var(--Primary-Colour, #4285F4);
        font-size: inherit;
    }

    @media (min-width: 768px) {
        font-size: 36px;
    }
}

h5 {
    color: #414141;
    font-size: 24px;
    font-weight: 600;
    line-height: 130%;
}

p {
    color: #848484;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}

a {
    text-decoration: none;
}


.container {
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;

    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: 1200px) {
        padding: unset;
    }

}

.logo {
    color: #0075FF;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 23px;
    font-weight: 700;
    line-height: normal;
}

.container-fluid {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;

    @media (min-width: 992px) {
        padding: unset;
    }
}

.btn {
    display: flex;
    width: 100%;
    padding: 19px 24px 19px 20px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: linear-gradient(0deg, #4285F4 0%, #4285F4 100%), linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);

    color: var(--White);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 130%;
    /* 22.1px */
    text-decoration: none;

    @media (min-width: 768px) {
        width: fit-content;
    }
}

.btn-isset {
    color: var(--Primary-Colour);
    padding: 11px 24px 11px 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 125%;
    /* 20px */
    letter-spacing: -0.24px;
    border-radius: 80px;
    border: 1px solid #F0F0F0;
    background: #FBFCFF;
}

.btn-medium {
    min-width: 234px;
    min-height: 66px;
}

.btn-less {
    padding: 10px 24px;
    font-size: 16px;
}
}