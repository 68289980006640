.recent-lessons-container{
    width: 100%;
   
}
.recent-lessons-search-wrapper{
    padding: 10px 21px 16px 44px;
    @media (max-width: 768px) {
        padding: 10px 10px 16px 10px;
    }
    width: 100%;
    .recent-lessons-search{
        width: 100%;
        border-bottom: 1px solid #EAEAEA;
        padding-top: 24px;
        padding-bottom: 12px;
        padding-left: 8px;
        display: flex;
        gap: 12px;
        height: 39px;
        box-sizing: unset;
        @media (max-width: 768px) {
            padding-top: 6px;
            padding-bottom: 6px;
            gap: 6px;
            img {
                width: 24px;
            }
        }
        input {
            flex: 1;
            border: none;
            font-size: 26px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: 100%;
            @media (max-width: 768px) {
                font-size: 18px;
            }
        }
    }
}
.recent-lessons {
    width: 100%;
    flex-shrink: 0;
    border-radius: 20px;
    border: 2px solid var(--White, #FFF);
    background: var(--White, #FFF);
    box-shadow: 3px 3px 36px 2px rgba(26, 26, 26, 0.07);
    border-radius: 20px;
    border: 2px solid #fff;
    padding: 21px 0px 21px 0px;
    position: relative;
    .btn-delete {
        cursor: pointer;
        position: absolute;
        right: 20px;
        color: #E8372D;
        text-align: center;
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .flex-table {
        display: flex;
        flex-direction: column;
    }

    .flex-row {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        padding: 0px 21px 0px 44px;
        @media (max-width: 768px) {
            padding: 0px 10px 0px 10px;
        }
    }
    .flex-row.data-row:hover {
        background: rgba(217, 217, 217, 0.22);
    }

    .flex-cell {
        // flex: 1;
        padding: 15px;
        gap: 30px;
        @media (max-width: 600px) {
            padding: 5px;
            gap: 15px;
        }
    }
    
    .lesson-item {
        flex: 1;
        color: #565656;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
    }
    .last-open {
        width: 177px;
        text-align: center;
        color: #565656;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        @media (max-width: 600px) {
            max-width: 80px;
        }
    }
    .mobile-view{
        flex: 1;
        width: 100%;
        .last-open{
            width: 100%;
            max-width: 100%;
            text-align: left;
            font-size: 12px;
            padding-bottom: 15px;
        }
    }
    .edit {
        width: 50px;
        text-align: center;
        color: #0075FF;
        font-size: 18px;
        font-style: normal;
        font-weight: 660;
        line-height: 100%;
        display: flex;
        align-items: center;
        @media (max-width: 600px) {
            width: 32px;
        }
        img {
            cursor: pointer;
            max-height: 20px;
        }
    }
    .flex-header {
        padding-bottom: 10px;
        cursor: unset;
        .flex-cell{
            align-self: center;
            line-height: 22px;
        }
        .flex-cell.lesson-item {
            color: #0075FF;
            font-size: 18px;
            padding-left: 0px
        }
        .flex-cell.last-open {
            color: #A4A4A4;
            font-size: 14px;
        }
        .flex-cell.edit {
            color: #0075FF;
            font-size: 14px;
            font-weight: 400;
            display: flex;
        }
    }
    .search-criteria {
        text-align: center;
    }
}