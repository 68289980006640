.info-dialog {
    padding: 28px 48px;
    @media (max-width: 768px) {
        padding: 28px 18px;
    }
    .btn-wrapper {
        display: flex;
        width: 380px;
        gap: 48px;
        margin: auto;
        @media (max-width: 768px) {
            max-width: 100%;
        }
        .btn-delete {
            font-weight: 500;
            font-size: 14px;
            line-height: 44px;
            align-items: center;
            text-align: center;
            color: #31302a;
            flex: none;
            order: 0;
            flex-grow: 0;
            width: 178px;
            background: #f7f5e8;
            border-radius: 8px;
            cursor: pointer;
            background: #31302a;
            color: #ffffff;
        }
    }
    label {
        color: #1A1A1A;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: block;
        @media (max-width: 768px) {
            font-size: 22px;
            max-width: 100%;
        }
    }
    img {
        margin: 16px auto 24px;
        display: flex;
    }
    .blue-btn, .black-btn {
        margin-top: 48px;
        @media (max-width: 600px) {
            margin-top: 24px;
        }
    }
}