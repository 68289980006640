.contact-us-wrapper{
    background: white;
    .contact-us {
        min-height: calc(100vh - 300px);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        background: linear-gradient(0deg, rgba(205, 219, 241, 0.3) -8.42%, rgba(205, 219, 241, 0) 94.6%);
    }
    img {
        width: 100%;
    }

    .card-wrapper {
        margin: auto;
    }
    .right-section{
        margin-left: -30px;
    }
    .left-section {
        min-width: 350px;
    }
    .desk-image{
        display: unset;
    }
    .mobile-image{
        display: none;
    }

    @media (max-width: 1000px) {
        .desk-image {
            display: none;
        }
        .mobile-image {
            display: unset;
        }
    }

    @media (max-width: 778px) {
            .card-wrapper {
            margin: 15px;
            margin-top: 40px;
        }
    }

    @media (max-width: 450px) {
        .card-wrapper {
            margin-top: 30px;
        }
    }

    .card {
        margin: auto;
        background-color: #ffffff;
        padding: 48px;
        display: table;
        border-radius: 32px;
        display: flex;
        max-width: 1200px;
        margin-bottom: 70px;
        @media (max-width: 1000px) {
            gap: 20px;
        }
    }
    @media (max-width: 1000px) {
        .card .right-section {
            margin-left: auto;
        }
    }

    @media (max-width: 900px) {
        .card {
            flex-direction: column-reverse;
        
        }
    }

    @media (max-width: 778px) {
            .card {
            padding: 45px;
        }
    }

    @media (max-width: 500px) {
        .card {
            width: 100%;
        }
    }

    @media (max-width: 450px) {
            .card {
            padding: 15px;
        }
    }

        .card .title {
        color: var(--Black, #2D2D2D);
        font-size: 58px;
        font-style: normal;
        font-weight: 660;
        line-height: 120%;
        margin-bottom: 20px;
    }
    .card .sub-title {
        color: #7A7A7A;
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
    }
    .card .email {
        color: var(--Black, #2D2D2D);
        font-family: Open Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 160%;
    }


    .card .send-mail {
        display: flex;
        width: 212px;
        height: 60px;
        padding: 11px 24px 11px 20px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 16px;
        background: linear-gradient(0deg, #4285F4 0%, #4285F4 100%), linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
        color: var(--white-01, var(--White, #FFF));
        text-align: center;
        font-family: Inter;
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        margin-top: 48px;
        cursor: pointer;
    }

       
}