body {
  margin: 0;
  /* font-family: "Inter", sans-serif; */
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px
}
.cursor-pointer {
  cursor: pointer;
}